import Vue from 'vue'
import Vuex from 'vuex'
import store from '../store/store'

import Request from './request'

import timeZones from '../constants/_TIME_ZONES.json'

Vue.use(Vuex)

const backend = store.state.config.konecta

function getTimeZone() {
  const index = timeZones.findIndex(
    item =>
      item.utc &&
      item.utc.indexOf(Intl.DateTimeFormat().resolvedOptions().timeZone) > -1
  )
  if (index > -1 && timeZones[index]) {
    return timeZones[index].text
  } else {
    const offset = (new Date().getTimezoneOffset() / 60) * -1
    const element = timeZones.find(e => e.offset === offset)
    if (element) {
      return element.text
    }
  }
  return undefined
}

function getHeaders() {
  return {
    authorization: `Bearer ${store.state.session.token}`,
    'X-Time-Zone': getTimeZone()
  }
}

export default {
  get(endpoint) {
    const config = {
      headers: getHeaders()
    }
    // console.log(`Bearer ${store.state.session.token}`)
    return Request.get(backend + endpoint, config)
  },
  post(endpoint, data) {
    const config = {
      headers: getHeaders()
    }
    return Request.post(backend + endpoint, data, config)
  },
  put(endpoint, id, data) {
    const config = {
      headers: getHeaders()
    }
    return Request.put(backend + endpoint + id, data, config)
  },
  delete(endpoint, id) {
    const config = {
      headers: getHeaders()
    }
    return Request.delete(backend + endpoint + id, config)
  },

  // subir archivos

  file(endpoint, data) {
    const config = {
      headers: getHeaders()
    }
    config.headers['content-type'] = 'multipart/form-data'
    return Request.post(backend + endpoint, data, config)
  }
}
