<template>
  <section id="clustering">
    <!-- create cluster -->
    <vs-sidebar
      position-right
      parent="body"
      default-index="1"
      color="primary"
      class="sidebarx"
      spacer
      v-model="showCreateCluster"
    >
      <div class="create-cluster">
        <h4 class="create-cluster__title">
          {{ lang.clustering.createCluster.title[languageSelected] }}
        </h4>
        <Filters
          :filter-model="filterModel"
          type="flat"
          :title="false"
          :icon="false"
          @init="filterChange"
          @on-apply="filterChange"
          :action-bar="false"
        />
        <vs-spacer></vs-spacer>
        <div class="create-cluster__actions">
          <vs-button
            type="flat"
            class="float-left mr-4"
            icon-pack="feather"
            color="danger"
            @click="cancel"
          >
            {{ lang.clustering.createCluster.cancel[languageSelected] }}
          </vs-button>
          <vs-button
            color="primary"
            @click="addCluster"
            :disabled="disabledCreate"
          >
            {{ lang.clustering.createCluster.create[languageSelected] }}
          </vs-button>
        </div>
      </div>
    </vs-sidebar>
    <ClusteringTableV1
      v-if="report"
      :report="report"
      :interval="interval"
      :services="services"
      @onCreate="openClusterPopup"
      ref="clustersTable"
    />
  </section>
</template>

<script>
import konecta from '@/services/konecta'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Clustering',
  components: {
    ClusteringTableV1: () =>
      import(
        '../../../metrics/charts/clustering_table_v1/ClusteringTableV1.vue'
      ),
    Filters: () => import('../../../metrics/filters/Filters.vue')
  },
  data() {
    return {
      report: null,
      reports: [],
      filterModel: [],
      services: [],
      interval: {},
      showCreateCluster: false
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    disabledCreate() {
      return !this.services || !this.services.length || !this.interval.from
    }
  },
  methods: {
    ...mapActions('clusters', ['createCluster']),
    parseRange(values) {
      const from = values[0]
      const to = values[1]

      return { from, to }
    },
    filterChange(filter) {
      switch (filter.type) {
        case 'botNoVersion':
          this.services = filter.value
          break
        case 'date':
          if (filter.value[0] && filter.value[1]) {
            this.interval = this.parseRange(filter.value)
          }
          break
        default:
          break
      }
    },
    weekInterval() {
      const to = new Date()
      let from = new Date(to.getTime())
      from.setDate(from.getDate() - 7)
      return {
        from: new Date(from.setUTCHours(0, 0, 0, 0)),
        to: new Date(to.setUTCHours(23, 59, 59, 999))
      }
    },
    getReport() {
      this.$vs.loading()
      konecta
        .get(
          '/report/5e7e2ce79b9725b2b6834d6d?select[name]=true&select[description]=true&select[reportType]=true&select[_extra]=true'
        )
        .then(response => {
          this.report = response.data
          this.reports = [this.report]
        })
        .catch(error => {
          this.$vs.notify({
            title: 'Error',
            text: '',
            color: 'danger',
            position: 'top-right'
          })
          console.error(error)
        })
        .finally(() => this.$vs.loading.close())
    },
    async openClusterPopup() {
      await this.$nextTick()
      this.showCreateCluster = true
    },
    async addCluster() {
      this.$vs.loading()
      try {
        const payload = {
          service: this.services[0],
          from: this.interval.from,
          to: this.interval.to
        }

        await this.createCluster(payload)
        await this.$refs.clustersTable.updateClusters()
        // const result = await konecta.post('/cluster', payload)

        this.$vs.notify({
          color: 'success',
          title: this.lang.clustering.createCluster.messages.success.title[
            this.languageSelected
          ],
          text: this.lang.clustering.createCluster.messages.success.text[
            this.languageSelected
          ]
        })
      } catch (error) {
        this.$vs.notify({
          color: 'danger',
          title: this.lang.clustering.createCluster.messages.error.title[
            this.languageSelected
          ],
          text: this.lang.clustering.createCluster.messages.error.title[
            this.languageSelected
          ]
        })
      } finally {
        this.$vs.loading.close()
      }
      this.showCreateCluster = false
    },
    cancel() {
      this.showCreateCluster = false
    }
  },
  async mounted() {
    await this.getReport()

    this.interval = this.weekInterval()

    this.filterModel = [
      {
        name: 'botsNoVersion',
        component: 'BotNoVersionFilter',
        width: 12,
        props: {
          multipleSelect: false,
          allowEmpty: true
        }
      },
      {
        name: 'dates',
        component: 'DateRangeFilter',
        width: 12,
        props: {
          value: this.interval
        }
      }
    ]
  }
}
</script>

<style lang="scss">
.vs-sidebar {
  z-index: 99999;
  max-width: 380px;

  .vs-sidebar--items {
    overflow: visible;
    display: flex;
    flex-direction: column;

    .create-cluster {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 20px;

      &__title {
        margin-bottom: 20px;
      }

      &__actions {
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }
    }
  }
}
</style>
